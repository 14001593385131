import { defineStore } from 'pinia';
import axios from '../axios';

export const useScenarioStore = defineStore({
	id: 'scenarios',
	state: () => ({
		scenarios: [],
		scenario: {},
		alerts: [],
		schedules: [],
		loader: false,
	}),
	getters: {
		orderedComments() {
			return [this.scenario.comments].sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
		},
		scenarioAssertions() {
			var assertions = [];
			// this.scenario.steps.forEach((element) => {
			// 	element.assertions_tags.forEach((assertion) => {
			// 		assertions.push(assertion);
			// 	});
			// 	element.assertions_dl.forEach((assertion) => {
			// 		assertions.push(assertion);
			// 	});
			// });
			return assertions;
		},
	},
	actions: {
		// SCENARIOS
		async getScenarios() {
			const { data } = await axios.get('/scenarios/');
			if (data) {
				data.forEach((scenario) => {
					scenario.runs = scenario.runs.sort((a, b) => (a.id > b.id ? 1 : -1));
				});
				this.scenarios = data;
			}
		},
		async getScenario(scenarioID) {
			const { data } = await axios.get(`/scenarios/${scenarioID}/`);
			if (data.runs) {
				data.runs.sort((a, b) => (a.id > b.id ? 1 : -1));
				this.scenario = data;
			}
		},
		async createScenario(scenario) {
			const { data } = await axios.post('/scenarios/', scenario);
			this.scenarios.push(data);
		},
		async updateScenario(updated_scenario) {
			const { data } = await axios.put(`/scenarios/${updated_scenario.id}/`, updated_scenario);
			this.scenario = data;
			return data;
		},
		async updateScenarioOptional(scenario) {
			const { data } = await axios.patch(`/scenarios/${scenario.id}/`, scenario);
			this.scenario = { ...this.scenario, ...data };
			return data;
		},
		async deleteScenario(scenarioID) {
			await axios.delete(`/scenarios/${scenarioID}/`);
			let index = this.scenarios.findIndex((s) => s.id == scenarioID);
			this.scenarios.splice(index, 1);
		},
		async copyScenario(scenarioID) {
			const { data } = await axios.post(`/scenarios/${scenarioID}/copy`);
			this.scenarios.push(data);
		},

		// EVENT x SCENARIO
		// to do : move to assertions.js

		async removeEventFromScenario({ event_id, scenario_id }) {
			await axios.put(`/scenario_remove_event/?scenario_id=${scenario_id}&event_id=${event_id}`).then((response) => (this.scenario = response.data));
		},
		// RUN
		async runScenario(scenarioID) {
			// const { data } = await axios.get(`/scenario/${scenarioID}/assertions`);
			const { data } = await axios.post(`/run_scenario/v2/${scenarioID}/`);

			this.scenario.runs.push(data);
		},
		async getTags(scenarioID) {
			// const { data } = await axios.post(`/scenario/${scenarioID}/tags`);
			const { data } = await axios.post(`/run_scenario/v2/${scenarioID}/`);
			this.scenario.steps = data.scenario.steps;
		},

		// STEPS
		async createStep({ scenarioID, step }) {
			const { data } = await axios.post(`/steps/?scenario_id=${scenarioID}`, step);
			this.scenario.steps.push(data);
		},
		async deleteStep(stepID) {
			await axios.delete(`/steps/${stepID}/`);
			let index = this.scenario.steps.findIndex((s) => s.id == stepID);
			this.scenario.steps.splice(index, 1);
		},
		async updateStep(step) {
			const { data } = await axios.put('/steps/', step);
			let index = this.scenario.steps.findIndex((s) => s.id == step.id);
			this.scenario.steps[index] = data;
		},
		async batchImport({ scenarioID, steps }) {
			const { data } = await axios.post(`/scenarios/${scenarioID}/batch-create`, steps);
			this.scenario.steps = data;
		},

		// ACTIONS

		async createAction({ action, stepID }) {
			const { data } = await axios.post(`/actions/?step_id=${stepID}`, action);
			console.log('data', data);
			this.scenario.steps.forEach((step) => {
				if (step.id === stepID) {
					step.actions.push(data.actions[data.actions.length - 1]);
				}
			});
		},

		async deleteAction(actionID) {
			await axios.delete(`/actions/${actionID}/`);
			this.scenario.steps.forEach((step) => {
				if (step.actions) {
					step.actions.forEach((action, index) => {
						action.id === actionID ? step.actions.splice(index, 1) : null;
					});
				}
			});
		},
		async updateAction(updatedAction) {
			const { data } = await axios.put('/actions/', updatedAction);
			let stepIndex = this.scenario.steps.findIndex((step) => step.id == updatedAction.step_id);
			let actionIndex = this.scenario.steps[stepIndex].actions.findIndex((action) => action.id == updatedAction.id);

			this.scenario.steps.forEach((step) => {
				if (step.actions) {
					step.actions.forEach((action) => {
						if (action.id === updatedAction.id) {
							this.scenario.steps[stepIndex].actions[actionIndex] = data;
						}
					});
				}
			});
		},

		// ASSERTIONS
		async createTagAssertion({ stepID, assertion }) {
			const { data } = await axios.post(`/assertion_tag/?step_id=${stepID}`, assertion);
			this.scenario.steps.forEach((step) => {
				if (step.id === stepID) {
					step.assertions_tags.push(data);
				}
			});
		},

		async deleteTagAssertion(assertionID) {
			await axios.delete(`/assertion_tag/${assertionID}/`);
			this.scenario.steps.forEach((step) => {
				if (step.assertions_tags) {
					step.assertions_tags.forEach((assertion_tag, index) => {
						assertion_tag.id === assertionID ? step.assertions_tags.splice(index, 1) : null;
					});
				}
			});
		},

		// SCHEDULE
		async updateSchedule({ scenarioID, frequency }) {
			const schedule = frequency.split(' ');
			const { data } = await axios.post(`/scenarios/schedule/?scenario_id=${scenarioID}&minute=${schedule[0]}&hour=${schedule[1]}&day=${schedule[2]}&month=${schedule[3]}&day_of_week=${schedule[4]}`);
			if (data.scheduled) {
				this.scenario.frequency = frequency;
			} else {
				this.scenario.frequency = '';
			}
		},
		async deleteSchedule(scenarioID) {
			console.log('store: delete Schedule');
			await axios.delete(`/scenarios/schedule/delete/?scenario_id=${scenarioID}`);
			this.scenario.frequency = '';
		},

		async getSchedules(scenarioID) {
			const { data } = await axios.get(`/schedule/show_schedules/`, scenarioID);
			this.schedules = data;
			return data;
		},

		// ALERTS
		async setAlert(alert) {
			const { data } = await axios.post(`/alerts/`, alert);
			this.alerts.push(data);
		},
		async deleteAlerts(alertID) {
			await axios.delete(`/alerts/${alertID}/`);
			let index = this.alerts.findIndex((s) => s.id == alertID);
			this.alerts.splice(index, 1);
		},

		async getAlerts(scenarioID) {
			const { data } = await axios.get(`/scenario/${scenarioID}/alerts/`);
			this.alerts = data;
			return data;
		},

		// COMMENTS
		async addComment({ scenarioID, comment }) {
			const { data } = await axios.post(`/comments/?scenario_id=${scenarioID}`, comment);
			this.scenario.comments.push(data);
		},

		// LOADER
		setLoading(value) {
			this.loader = value;
		},
	},
});
